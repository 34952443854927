<template>
	<div>
		<pui-datatable v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"

			:modalDialog="modalDialog"
			:showDeleteBtn="false"
			:navigableDetail="false"
			:showCreateBtn="false"
			:showToolbar="false"
			:showPaginator="false"
		></pui-datatable>
		<v-layout dense>
			<v-flex xs12 md8 lg9>
				<pui-select
					:id="`resolucion-condicion-idconcod`"
					attach="idconcod"
					:label="$t('resolucion.idconcod')"
					toplabel
					clearable
					required
					:disabled="formDisabled"
					v-model="parentModel"
					modelName="vlupmrescondicion"
					:itemsToSelect="[{ idconcod: parentModel.idconcod }]"
					:modelFormMapping="{ idconcod: 'idconcod' }"
					itemValue="idconcod"
					:itemText="item => `${item.codconresedi} - ${item.name}`"
					:order="{ name: 'asc' }"
					:filterId="`codpue-resolucion-condicion`"
					:filterMap="{ codpue: this.parentModel.codpue }"
				></pui-select>
			</v-flex>
		</v-layout>
		<v-layout dense>
			<v-flex xs12>
				<pui-text-field
					:id="`resolucion-condicion-connocod`"
					v-model="parentModel.connocod"
					:label="$t('resolucion.connocod')"
					:disabled="formDisabled"
					required
					toplabel
					maxlength="10"
				></pui-text-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'resolucioncondicion-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		//'resolucion-modals': resolucionModals
	},
	data() {
		return {
			modelName: 'resolucioncondicion',
			//actions: resolucionActions.gridactions,
			modelColumnDefs: {
			},
			masterDetailColumnVisibles: {
				idrescon:true,
				cond:true,
				fconnocod:true
			},
			formDisabled:false
		}
	},
	mounted() {
			console.log(this.parentModel);
			//console.log(this.pk);

	}
}
</script>
